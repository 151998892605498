<template>
  <div class="d-flex flex-column justify-content-center">
    <div class="">
      <simple-svg :src="require('@/assets/images/icons/empty-search.svg')" />
    </div>
    <div class="font-size-h6 font-weight-bold text-dark-75 py-9">
      {{ description }}
    </div>
    <div class="mt-7">
      <b-button
        variant="light"
        @click="action"
        class="btn-small font-weight-bold"
        pill
      >
        {{ buttonText }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { SimpleSVG } from "vue-simple-svg";

export default {
  name: "EmptySearch",
  props: {
    action: {
      type: Function,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    }
  },
  components: {
    "simple-svg": SimpleSVG
  }
};
</script>
