<template>
  <div id="departments">
    <div class="subheader pb-2 pb-lg-4 subheader-transparent" id="kt_subheader">
      <div
        class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap p-0"
      >
        <!--begin::Details-->
        <div class="d-flex align-items-center flex-wrap mr-2">
          <!--begin::Title-->
          <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">
            {{ $t("menu.controller.departments.title") }}
          </h5>
          <!--end::Title-->
          <!--begin::Separator-->
          <div
            class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200"
          ></div>
          <!--end::Separator-->
          <!--begin::Search Form-->
          <div class="d-flex align-items-center" id="kt_subheader_search">
            <span class="text-dark-50 font-weight-bold" id="kt_subheader_total"
              >{{ total }}
              {{
                $t("tables.total", {
                  module: $tc("tables.department", total).toLowerCase()
                })
              }}</span
            >
            <div class="ml-5">
              <div
                class="input-group input-group-sm input-group-solid"
                style="max-width: 175px"
              >
                <input
                  type="text"
                  class="form-control"
                  id="search"
                  v-model="search"
                  :placeholder="$t('tables.search') + ' ...'"
                />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <span class="svg-icon">
                      <!--begin::Svg Icon | path:/metronic/theme/html/demo9/dist/assets/media/svg/icons/General/Search.svg-->
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M14.293 16.707a1 1 0 011.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 16a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 110-14 7 7 0 010 14z" fill="#000" fill-rule="nonzero"/></g></svg>
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center" id="kt_subheader_search">
            <div class="d-flex" v-if="showFilters">
              <div class="ml-5">
                <div class="input-group input-group-sm input-group-solid">
                  <b-form-select
                    class="form-control form-control-solid"
                    v-model="searchTagKeys"
                    :options="tagKeys"
                    @change="searchTags = false"
                  />
                </div>
              </div>
              <div class="ml-5" v-if="searchTagKeys !== false">
                <div class="input-group input-group-sm input-group-solid">
                  <b-form-select
                    class="form-control form-control-solid"
                    v-model="searchTags"
                    :options="tags"
                  />
                </div>
              </div>
            </div>
            <div v-if="hasModule(['risk'])" class="d-flex">
              <div class="ml-5" v-if="showFilters">
                <div class="input-group input-group-sm input-group-solid">
                  <b-form-select
                    class="form-control form-control-solid"
                    v-model="searchGri"
                    :options="griOptions"
                  />
                </div>
              </div>
              <div class="ml-5" v-if="showFilters">
                <div class="input-group input-group-sm input-group-solid">
                  <b-form-select
                    class="form-control form-control-solid"
                    v-model="searchRisk"
                    :options="riskOptions"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div class="ml-5" v-if="!showFilters">
                <b-button
                  variant="light-primary"
                  class="ml-2 font-weight-bold"
                  v-b-tooltip.right.hover
                  :title="$t('tables.filter')"
                  @click="showFilters = !showFilters"
                >
                  <i class="fas fa-filter" />
                </b-button>
              </div>
              <div class="ml-5" v-if="showFilters">
                <b-button
                  variant="light-primary"
                  class="ml-2 font-weight-bold"
                  v-b-tooltip.right.hover
                  :title="$t('tables.filter')"
                  @click="resetFilters"
                >
                  <i class="fas fa-times" />
                </b-button>
              </div>
            </div>
          </div>
          <!--end::Search Form-->
        </div>
        <!--end::Details-->
        <!--begin::Toolbar-->
        <div class="d-flex align-items-center">
          <!--begin::Button-->
          <b-button
            @click="toggleGrid"
            variant="light-primary"
            class="ml-2 font-weight-bold"
            v-b-tooltip.left.hover
            :title="grid ? $t('tables.views.table') : $t('tables.views.grid')"
          >
            <b-icon-grid v-if="!grid" />
            <b-icon-list v-if="grid" />
          </b-button>
          <json-excel
            :data="filtered"
            :fields="excelFields"
            v-b-tooltip.left.hover
            :title="$t('tables.download')"
            type="xls"
            name="departments"
            class="btn btn-light-primary btn-icon ml-3"
          >
            <span class="svg-icon svg-icon-light-primary px-2">
              <simple-svg
                :src="require('@/assets/images/icons/downloaded-file.svg')"
              />
            </span>
          </json-excel>
          <!--end::Button-->
        </div>
        <!--end::Toolbar-->
      </div>
    </div>
    <b-card
      class="card-custom card-stretch card-rounded"
      v-if="!grid && !isLoading"
    >
      <b-card-body>
        <b-table
          id="departments-table"
          style="overflow-y: hidden;"
          hover
          :items="filtered"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
          primary-key="stakeholderId"
          :responsive="true"
          show-empty
        >
          <template>
            <empty-search
              :description="$t(`tables.empty.description`)"
              :buttonText="$t(`tables.empty.button`)"
              :action="resetFilters"
            />
          </template>
          <template #cell(name)="row">
            <div class="row">
              <div class="col-12 text-left">
                <div class="mb-1">{{ row.value }}</div>
                <div class="d-flex">
                  <b-progress
                    class="progress-xs mt-2 mb-2 flex-shrink-0 w-150px w-xl-250px"
                  >
                    <b-progress-bar
                      :max="10"
                      :animated="false"
                      :value="
                        row.item.progress !== false ? row.item.progress : '-'
                      "
                      :style="
                        `background-color: ${getHexByValue(
                          row.item.progress,
                          10
                        )}`
                      "
                      :striped="false"
                    />
                  </b-progress>
                  <span class="font-weight-bold text-dark ml-4">{{
                    $n(row.item.progress / 10, "percent")
                  }}</span>
                </div>
              </div>
            </div>
          </template>
          <template #cell(insider)="row">
            <b-badge class="base-full p-3" pill style="font-size: 1rem;">
              {{ row.value }}
            </b-badge>
          </template>
          <template #cell(risk)="row">
            <b-badge
              :class="variantByValue(row.item.risk, 6)"
              pill
              class="p-3 font-weight-bolder"
              style="font-size: 1rem;"
            >
              {{ row.value }} / 6
            </b-badge>
          </template>
          <template #cell(hazard)="row">
            <b-badge
              :class="variantByValue(row.item.hazard, 3)"
              pill
              class="p-3 font-weight-bolder"
              style="font-size: 1rem;"
            >
              {{ row.value }} / 3
            </b-badge>
          </template>
          <template #cell(wellbeing)="row">
            <b-badge
              :class="variantByValue(row.item.wellbeing, 10)"
              style="font-size: 1rem;"
              pill
              class="p-3 font-weight-bolder"
            >
              {{ row.value }} / 10
            </b-badge>
          </template>
          <template #cell(cyber)="row">
            <b-badge
              style="font-size: 1rem;"
              :class="variantByValue(row.item.cyber, 10)"
              pill
              class="p-3 font-weight-bolder"
            >
              {{ row.value }} / 10
            </b-badge>
          </template>
          <template #cell(actions)="row">
            <b-button
              pill
              variant="light-primary"
              class="mr-2 px-auto"
              @click="viewDetail(row.item.stakeholderId)"
              v-b-tooltip.right.hover
              :title="$t('tables.seeDetail')"
            >
              <i class="la la-eye pl-1" />
            </b-button>
            <b-button
              pill
              variant="light-primary"
              class="mr-2 px-auto"
              v-b-tooltip.right.hover
              :title="$t('tables.employees')"
              @click="
                $router.push({
                  name: 'Users',
                  query: { department: row.item.stakeholderId }
                })
              "
            >
              <i class="la la-users pl-1" />
            </b-button>
          </template>
        </b-table>
        <div class="flex-wrap mx-auto" v-if="filtered.length > 0">
          <b-pagination
            class="d-flex flex-wrap mt-3 py-2 mr-3"
            v-model="currentPage"
            :total-rows="filtered.length"
            :per-page="perPage"
            aria-controls="departments-table"
            first-number
            pill
            last-number
            align="center"
            size="lg"
          ></b-pagination>
        </div>
      </b-card-body>
    </b-card>
    <b-row v-if="grid && !isLoading">
      <b-col
        lg="4"
        v-for="department in filtered"
        :key="department.stakeholderId"
      >
        <b-card class="card-custom gutter-b mb-6">
          <!--begin::Section-->
          <div class="d-flex align-items-center">
            <!--begin::Pic-->
            <div class="flex-shrink-0 mr-7">
              <div class="symbol symbol-20 symbol-lg-60">
                <span
                  :class="
                    'font-size-h3 symbol-label font-weight-boldest ' +
                      variantByValue(department.risk, 6, true, true)
                  "
                >
                  {{ getInitials(department) }}
                </span>
              </div>
            </div>
            <!--end::Pic-->
            <!--begin::Info-->
            <div class="d-flex flex-column mr-auto">
              <!--begin: Title-->
              <div
                class="card-title text-hover-primary font-weight-bolder font-size-h5 text-dark mb-1 text-left"
              >
                {{ department.name }}
              </div>
              <!--end::Title-->
            </div>
            <!--end::Info-->
          </div>
          <!--end::Section-->
          <!--begin::Content-->
          <div class="d-flex flex-wrap mt-14">
            <div
              class="mr-12 d-flex flex-column mb-7"
              v-if="hasModule(['risk'])"
            >
              <span class="d-block font-weight-bold mb-4 text-left">{{
                $t("tables.risk")
              }}</span>
              <span
                :class="
                  'btn btn-light-primary btn-sm font-weight-bold btn-upper btn-text ' +
                    variantByValue(department.risk, 6)
                "
                >{{ department.risk || "?" }} / 6</span
              >
            </div>
            <div
              class="mr-12 d-flex flex-column mb-7"
              v-if="hasModule(['impact'])"
            >
              <span class="d-block font-weight-bold mb-4 text-left">{{
                $t("tables.hazard")
              }}</span>
              <span
                :class="
                  'btn btn-light-primary btn-sm font-weight-bold btn-upper btn-text ' +
                    variantByValue(department.hazard, 3)
                "
                >{{ department.hazard || "?" }} / 3</span
              >
            </div>
            <!--begin::Progress-->
            <div class="flex-row-fluid mb-7" v-if="hasModule(['risk'])">
              <span class="d-block font-weight-bold mb-4 text-left">
                {{ $t("tables.progress") }}
              </span>
              <div class="d-flex align-items-center pt-2">
                <b-progress class="progress progress-xs mt-2 mb-2 w-100">
                  <b-progress-bar
                    :max="10"
                    :animated="false"
                    :value="
                      department.progress !== false ? department.progress : '-'
                    "
                    :style="
                      `background-color: ${getHexByValue(
                        department.progress,
                        10
                      )}`
                    "
                    :striped="false"
                  />
                </b-progress>
                <span class="ml-3 font-weight-bold">{{
                  $n(department.progress / 10, "percent")
                }}</span>
              </div>
            </div>
            <!--end::Progress-->
          </div>
          <!--end::Content-->
          <!--begin::Blog-->
          <div class="d-flex flex-wrap">
            <!--begin: Item-->
            <div
              class="mr-12 d-flex flex-column mb-7"
              v-if="hasModule(['awareness'])"
            >
              <span class="font-weight-bold mb-4">
                {{ $t("tables.cyber") }}
              </span>
              <b-badge
                :class="variantByValue(department.cyber, 10)"
                style="font-size: 1rem;"
                pill
                class="p-3 font-weight-bolder"
              >
                {{ department.cyber ? $n(department.cyber) : "?" }} / 10
              </b-badge>
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div
              class="mr-12 d-flex flex-column mb-7"
              v-if="hasModule(['wellbeing'])"
            >
              <span class="font-weight-bold mb-4">
                {{ $t("tables.wellbeing") }}
              </span>
              <b-badge
                :class="variantByValue(department.wellbeing, 10)"
                style="font-size: 1rem;"
                pill
                class="p-3 font-weight-bolder"
              >
                {{ department.wellbeing ? $n(department.wellbeing) : "?" }} / 10
              </b-badge>
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div class="mr-12 d-flex flex-column mb-7">
              <span class="font-weight-bold mb-4">
                {{ $t("tables.employees") }}
              </span>
              <div class="symbol-group symbol-hover mx-auto">
                <div class="symbol symbol-30 symbol-circle symbol-light">
                  <span class="symbol-label font-weight-bold">
                    {{ department.peopleCount }}
                  </span>
                </div>
              </div>
            </div>
            <!--end::Item-->
            <!--begin::Item-->
            <div
              class="mr-12 d-flex flex-column mb-7"
              v-if="hasModule(['risk'])"
            >
              <span class="font-weight-bold mb-4">
                {{ $t("tables.insider") }}
              </span>
              <div class="symbol-group symbol-hover mx-auto">
                <div class="symbol symbol-30 symbol-circle symbol-light">
                  <b-badge class="base-full p-3" pill style="font-size: 1rem;">
                    {{
                      department.insider
                        ? $t(
                            `tables.dimensionIds.dimension.${department.insider}.DEFAULT.title`
                          )
                        : "-"
                    }}
                  </b-badge>
                </div>
              </div>
            </div>
            <!--end::Item-->
          </div>
          <!--end::Blog-->
          <template #footer>
            <div class="d-flex align-items-right">
              <b-button
                variant="dark"
                class="font-weight-bolder mt-sm-0 ml-sm-auto"
                size="md"
                pill
                @click="
                  $router.push({
                    name: 'Users',
                    query: { department: department.stakeholderId }
                  })
                "
              >
                {{ $t("tables.users") }}
              </b-button>
              <b-button
                variant="dark"
                class="font-weight-bolder mr-auto mr-sm-0 ml-4"
                size="md"
                pill
                @click="viewDetail(department.stakeholderId)"
              >
                {{ $t("tables.seeDetail") }}
              </b-button>
            </div>
          </template>
        </b-card>
      </b-col>
      <b-col cols="12" v-if="filtered.length < 1">
        <empty-search
          :description="$t(`tables.empty.description`)"
          :buttonText="$t(`tables.empty.button`)"
          :action="resetFilters"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";

import { mapGetters, mapActions } from "vuex";
import {
  getClassByValueAndBase,
  getColorByValueAndBase
} from "@/utils/helpers";
import { getTags } from "@/api/controller/stakeholders.api";

import EmptySearch from "@/components/EmptySearch.component";
import { BIconGrid, BIconList } from "bootstrap-vue";
import JsonExcel from "vue-json-excel";
import { SimpleSVG } from "vue-simple-svg";

export default {
  name: "Departments",
  components: {
    EmptySearch,
    BIconGrid,
    JsonExcel,
    BIconList,
    "simple-svg": SimpleSVG
  },
  data() {
    return {
      perPage: 10,
      grid: false,
      showFilters: false,
      searchRisk: false,
      searchGri: false,
      searchTags: false,
      searchTagKeys: false,
      search: "",
      currentPage: 1,
      allTags: []
    };
  },
  computed: {
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("Auth", ["hasModule"]),
    ...mapGetters("Departments", ["isLoading", "departments"]),
    fields() {
      var fields = [
        {
          key: "name",
          label: this.$tc(`tables.department`, 1),
          sortable: true,
          class: "align-middle",
          formatter: (val, key, item) => {
            return item.name;
          }
        },
        {
          key: "insider",
          label: this.$t(`tables.insider`),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          class: "align-middle",
          service: "risk",
          formatter: val => {
            return val !== false
              ? this.$t(`tables.dimensionIds.dimension.${val}.DEFAULT.title`)
              : "-";
          }
        },
        {
          key: "risk",
          label: this.$t(`tables.risk`),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          class: "align-middle",
          service: "risk",
          formatter: val => {
            return val !== false ? val : "-";
          }
        },
        {
          key: "hazard",
          label: this.$t(`tables.hazard`),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          class: "align-middle",
          service: "impact",
          formatter: val => {
            return val !== false ? val : "-";
          }
        },
        {
          key: "cyber",
          label: this.$t(`tables.cyber`),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          class: "align-middle",
          service: "awareness",
          formatter: val => {
            return val !== false ? this.$n(val) : "-";
          }
        },
        {
          key: "wellbeing",
          label: this.$t(`tables.wellbeing`),
          sortable: true,
          filterByFormatted: true,
          sortByFormatted: true,
          class: "align-middle",
          service: "wellbeing",
          formatter: val => {
            return val !== false ? this.$n(val) : "-";
          }
        },
        {
          key: "peopleCount",
          label: this.$tc("tables.employees", 1),
          sortable: true,
          sortByFormatted: true,
          class: "align-middle text-center"
        },
        {
          key: "actions",
          label: this.$t("tables.actions"),
          sortable: false,
          class: "align-middle"
        }
      ];
      return _.filter(fields, item => {
        return item.service === undefined || this.hasModule([item.service]);
      });
    },
    excelFields() {
      var fields = {};
      fields[this.$tc("tables.department", 1)] = "name";
      fields[this.$tc("tables.progress")] = {
        name: "progress",
        callback: value =>
          this.$n(value.progress ? value.progress / 10 : 0, "percent")
      };
      if (this.hasModule(["risk"])) {
        fields[this.$t(`tables.insider`)] = {
          name: "insider",
          callback: value =>
            value.insider
              ? this.$t(
                  `tables.dimensionIds.dimension.${value.insider}.DEFAULT.title`
                )
              : "-"
        };
        fields[this.$t(`tables.risk`)] = {
          name: "risk",
          callback: value =>
            value.risk ? `=CONCAT("${value.risk}";"/";"6")` : "-"
        };
        fields[this.$t(`tables.hazard`)] = {
          name: "risk",
          callback: value =>
            value.risk ? `=CONCAT("${value.hazard}";"/";"3")` : "-"
        };
      }
      if (this.hasModule(["awareness"])) {
        fields[this.$t(`tables.cyber`)] = {
          name: "cyber",
          callback: value => (value.cyber ? this.$n(value.cyber) : "-")
        };
      }
      if (this.hasModule(["wellbeing"])) {
        fields[this.$t(`tables.wellbeing`)] = {
          name: "wellbeing",
          callback: value => (value.wellbeing ? this.$n(value.wellbeing) : "-")
        };
      }
      return fields;
    },
    total() {
      return _.size(this.filtered);
    },
    tags() {
      let result = [
        {
          value: false,
          text: this.$t("tables.filterBy", {
            column: this.$t("campaigns.form.tagValues.title").toLowerCase()
          })
        }
      ];

      _.each(_.map(this.departments, "tags"), e => {
        let element = e[this.searchTagKeys];
        if (element !== undefined) {
          result = _.concat(result, e[this.searchTagKeys]);
        }
      });

      return _.uniq(result);
    },
    tagKeys() {
      let result = [
        {
          value: false,
          text: this.$t("tables.filterBy", {
            column: this.$t("campaigns.form.tagKeys.title").toLowerCase()
          })
        }
      ];
      _.each(this.allTags, e => {
        if (_.size(e.values) > 0) {
          result.push({ value: e.id, text: e.name });
        }
      });

      return result;
    },
    filtered() {
      var departments = this.departments;
      if (this.search !== "") {
        const regex = new RegExp(`\\b${_.deburr(this.search)}`, "i");
        departments = _.filter(departments, department => {
          return regex.test(_.deburr(department.name));
        });
      }
      if (this.searchRisk !== false) {
        departments = _.filter(departments, user => {
          return parseInt(user.risk) === parseInt(this.searchRisk);
        });
      }
      if (this.searchGri !== false) {
        departments = _.filter(departments, user => {
          return parseInt(user.insider) === parseInt(this.searchGri);
        });
      }
      if (this.searchTags !== false) {
        departments = _.filter(departments, user => {
          return _.includes(
            _.flattenDeep(_.values(user.tags)),
            this.searchTags
          );
        });
      }
      return departments;
    },
    riskOptions() {
      var result = [
        {
          value: false,
          text: this.$t("tables.filterBy", {
            column: this.$t("tables.risk").toLowerCase()
          })
        }
      ];
      for (let i = 1; i <= 6; i++) {
        result.push({
          value: i,
          text: this.$t("tables.filters.risk", { value: i })
        });
      }
      return result;
    },
    griOptions() {
      var result = [
        {
          value: false,
          text: this.$t("tables.filterBy", {
            column: this.$t("tables.insider")
          })
        }
      ];
      var insiders = _.keysIn(
        _.groupBy(this.departments, department => department.insider)
      );
      _.each(insiders, insider => {
        if (insider !== "false" && insider !== undefined) {
          result.push({
            value: insider,
            text: this.$t(
              `tables.dimensionIds.dimension.${insider}.DEFAULT.title`
            )
          });
        }
      });
      return result;
    }
  },
  methods: {
    ...mapActions("Departments", ["loadDepartments"]),
    toggleGrid() {
      this.grid = !this.grid;
      var view = JSON.parse(localStorage.getItem("view"));
      if (view && view.departments) {
        view.departments.grid = this.grid;
      } else if (view) {
        view.departments = {
          grid: this.grid
        };
      } else {
        view = {
          departments: {
            grid: this.grid
          }
        };
      }
      localStorage.setItem("view", JSON.stringify(view));
    },
    variantByValue(value, base = 100, color = true, bg = true) {
      return getClassByValueAndBase(value, base, color, bg);
    },
    getHexByValue(value, base) {
      return getColorByValueAndBase(value, base);
    },
    viewDetail(stakeholderId) {
      this.$router.push({
        name: "DepartmentDetail",
        params: {
          id: stakeholderId
        }
      });
    },
    getInitials(department) {
      var initials = "";
      _.each(_.words(department.name), word => {
        initials = initials + word.substring(0, 1);
      });
      return initials;
    },
    resetFilters() {
      this.showFilters = false;
      this.searchRisk = false;
      this.searchGri = false;
      this.searchTags = false;
      this.searchTagKeys = false;
      this.search = "";
    }
  },
  mounted() {
    var view = localStorage.getItem("view");
    if (view) {
      view = JSON.parse(view);
      this.grid = view.departments ? view.departments.grid : true;
    }
    if (this.isConstantsActive && !this.isLoading) this.loadDepartments();

    getTags(localStorage.getItem("managedCompanyId"), { allstatus: 0 }).then(
      tagsResp => {
        this.allTags = tagsResp.data.records;
      }
    );
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) this.loadDepartments();
    },
    areUsersLoaded(value) {
      if (this.isConstantsActive && value === false) this.loadDepartments();
    }
  }
};
</script>
